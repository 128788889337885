import React from 'react';

import { graphql } from 'gatsby';
import { ProductCardHeader } from 'components/productCard';

const TemplateProdutos = (props) => {
  //PROPS
  const {
    data: { productPage },
  } = props;
  //PROPS

  console.log(productPage);

  return (
    <>
      <ProductCardHeader data={productPage.product} slug={productPage.slug} />
    </>
  );
};

export const query = graphql`
  query Produto($id: String) {
    productPage: wpProduto(id: { eq: $id }) {
      title
      id
      slug
      seo {
        slug
      }
      product {
        text
        title
        imagens {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
            }
          }
        }
        imagem {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
            }
          }
        }
      }
    }
  }
`;

export default TemplateProdutos;
