import styled from 'styled-components';

import { black, white } from 'theme/colors';
import { container } from 'theme/sizes';
import { toRem } from 'utils/mixins';
import { blue } from '../../theme/colors';

export const HeaderWrapper = styled.div`
  position: relative;
  max-width: ${container.small};
  width: calc(100% - 2.5rem);
  margin: 20px auto;
  display: flex;
  align-items: flex-start;

  .proheader-col {
    width: 50%;
    padding: 40px;

    &:first-of-type {
    }
  }

  h2 {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 50px;
    &::after {
      content: '';
      height: 2px;
      width: 50px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: ${blue.default};
    }
  }
`;
