import React from 'react';

import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import FavComponent from '../favComp';

import { HeaderWrapper } from './ProductCard.styles';

const ProductCardHeader = (props) => {
  //PROPS
  const { data, slug } = props;
  //PROPS

  console.log('asd', data);

  //

  return (
    <HeaderWrapper>
      <div className="proheader-col">
        {data.imagens &&
          data.imagens.map((img, index) => {
            return (
              <GatsbyImage
                image={img?.localFile?.childImageSharp?.gatsbyImageData}
                alt={img?.altText}
              />
            );
          })}
      </div>
      <div className="proheader-col">
        <FavComponent slug={slug} />
        <h2>{data.title}</h2>
        <p>{data.text}</p>
      </div>
    </HeaderWrapper>
  );
};

export default ProductCardHeader;
